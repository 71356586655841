import gql from 'graphql-tag';

export default gql`
  query brands($name: String, $categoryName: String, $categoryIds: [Int], $pagination: Pagination!) {
    brands(name: $name, categoryName: $categoryName, categoryIds: $categoryIds, pagination: $pagination) {
      totalResults
      totalPage
      data {
        id
        slug
        name
        description
        description_vi
        imageUrl
        createdAt
        updatedAt
        productCategories {
          id
          slug
          name
          activated
        }
      }
    }
  }
`;
