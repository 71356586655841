/*
 * BreadCrumb Messages
 *
 * This contains all the text for the Side nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.BreadCrumb';

export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Trang Chủ',
  },
  market: {
    id: `${scope}.market`,
    defaultMessage: 'Chợ',
  },
  bestDeals: {
    id: `${scope}.bestDeals`,
    defaultMessage: 'Giảm giá',
  },
  newProducts: {
    id: `${scope}.newProducts`,
    defaultMessage: 'Sản phẩm mới',
  },
  bestPrices: {
    id: `${scope}.bestPrices`,
    defaultMessage: 'Giá tốt nhất',
  },
  combo: {
    id: `${scope}.combo`,
    defaultMessage: 'Combo',
  },
  recommended: {
    id: `${scope}.recommended`,
    defaultMessage: 'Đề xuất cho bạn',
  },
  cpProducts: {
    id: `${scope}.cpProducts`,
    defaultMessage: 'Sản phẩm của CP',
  },
  jpProducts: {
    id: `${scope}.jpProducts`,
    defaultMessage: 'Sản phẩm Nhật Bản',
  },
  bookInAdvance: {
    id: `${scope}.bookInAdvance`,
    defaultMessage: 'Đặt trước',
  },
  vinaMilkDalatMilk: {
    id: `${scope}.vinaMilkDalatMilk`,
    defaultMessage: 'Sữa Vinamilk & Dalat',
  },
  torani: {
    id: `${scope}.torani`,
    defaultMessage: 'Torani',
  },
  vegetableSuperSaving: {
    id: `${scope}.vegetableSuperSaving`,
    defaultMessage: 'Rau củ 10Kg siêu tiết kiệm',
  },
  fruits: {
    id: `${scope}.fruits`,
    defaultMessage: 'Trái cây',
  },
  dishWashingLiquid: {
    id: `${scope}.dishWashingLiquid`,
    defaultMessage: 'Nước rửa chén Kamereo',
  },
  lettuce: {
    id: `${scope}.lettuce`,
    defaultMessage: 'Xà lách',
  },
  meatZero: {
    id: `${scope}.meatZero`,
    defaultMessage: 'Thịt thực vật',
  },
  dalatCarrot: {
    id: `${scope}.dalatCarrot`,
    defaultMessage: 'Cà rốt Đà Lạt',
  },
  flashSales: {
    id: `${scope}.flashSales`,
    defaultMessage: 'Flash sales',
  },
  favorite: {
    id: `${scope}.favorite`,
    defaultMessage: 'Sản phẩm yêu thích',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Thông tin tài khoản',
  },
  overview: {
    id: `${scope}.overview`,
    defaultMessage: 'Báo cáo tổng quan',
  },
  analysis: {
    id: `${scope}.analysis`,
    defaultMessage: 'Thống kê theo sản phẩm',
  },
  itemAnalysis: {
    id: `${scope}.itemAnalysis`,
    defaultMessage: 'Thống kê theo sản phẩm',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Thông báo',
  },
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Đặt hàng',
  },
  companyInfo: {
    id: `${scope}.companyInfo`,
    defaultMessage: 'Công ty',
  },
  store: {
    id: `${scope}.store`,
    defaultMessage: 'Cửa hàng',
  },
  groupsManagement: {
    id: `${scope}.groupsManagement`,
    defaultMessage: 'Quản lý nhóm',
  },
  groupsInformation: {
    id: `${scope}.groupsInformation`,
    defaultMessage: 'Chi tiết nhóm',
  },
  usersManagement: {
    id: `${scope}.usersManagement`,
    defaultMessage: 'Người dùng',
  },
  userInformation: {
    id: `${scope}.userInformation`,
    defaultMessage: 'Thông tin người dùng',
  },
  orderHistory: {
    id: `${scope}.orderHistory`,
    defaultMessage: 'Lịch sử đơn hàng',
  },
  orderDetail: {
    id: `${scope}.orderDetail`,
    defaultMessage: 'Chi tiết đơn hàng',
  },
  orderPendingApproval: {
    id: `${scope}.orderPendingApproval`,
    defaultMessage: 'Đơn hàng đang chờ duyệt',
  },
  searchResults: {
    id: `${scope}.searchResults`,
    defaultMessage: 'Kết quả tìm kiếm',
  },
  brand: {
    id: `${scope}.brand`,
    defaultMessage: 'Thương hiệu',
  },
});
