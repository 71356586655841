import { Menu } from 'antd';
import { ContainerState } from 'containers/MainLayout/types';
import React, { FC, useMemo } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Category } from 'types/schema';
import getSelectedCategory from 'utils/getSelectedCategory';
import media from 'utils/mediaStyle';
import SVGIcon from '../SVGIcon/SVGIcon';
import { ErrorBoundary } from './ErrorBoundary';
import Icons from './iconsNewDesign';
import rightArrow from './rightArrow.svg';
import { ALL_PRODUCTS, tags } from './sidebarFilterData';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';
import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';

const Icon = styled(SVGIcon)`
  width: 24pt;
  height: 24pt;
  margin: auto;
`;

const Label = styled.div`
  text-align: left;
  font-size: 14px;
  flex: 1;
  ${media.lg`
    margin-left: 8px;
  `};
`;

const SubLabel = styled(Link)`
  white-space: nowrap;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-color: white;
  font-weight: 400;
  &:hover,
  &.selected {
    color: #2f904f !important;
  }
`;

const LabelWrapper = styled.div`
  min-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  color: inherit;
  ${media.lg`
    display: flex;
  `};
  &.category-title {
    padding: 10px 0 14px 16px;
    div {
      font-size: 16px;
      color: #424242;
      font-weight: 600;
      margin-left: 0;
    }
  }
`;

const CategoryNavContainer = styled.nav`
  border-collapse: collapse;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
  .ant-menu-horizontal {
    border-bottom: none;
  }
  border-radius: 6px;

  .ant-menu {
    border-radius: 6px;
    border: 1px solid rgba(158, 158, 158, 0.2);
    font-weight: 400;
  }
  .ant-menu-submenu .ant-menu-sub {
    padding: 0 8px !important;
  }
  .ant-menu .ant-menu-submenu-title {
    height: auto;
    padding: 4pt;
    line-height: 26px;
    font-weight: 400;

    .ant-menu-submenu-arrow {
      display: none;
    }
  }
  .ant-menu li:last-child {
    .ant-menu-submenu-title {
      border-bottom: none;
    }
  }
  .ant-menu-submenu:not(:first-child) {
    .ant-menu-submenu-title a {
      border-top: 1px solid rgba(158, 158, 158, 0.2);
    }
  }
`;
const CategoryNavShadow = styled.div`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: -1;
  position: absolute;
  right: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
  display: none;
  ${media.lg`
    display: block;
  `};
`;

const CategoryWrapper = styled.div`
  display: flex;
  .submenu-list {
    .ant-menu {
      padding: 0 8px;
    }
  }
  .ant-menu-sub .ant-menu-item:hover {
    background: unset !important;
    color: #2f904f !important;
  }
`;

const SubMenu = styled(Menu.SubMenu)`
  &:first-of-type {
    a {
      overflow: hidden;
    }
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    a {
      padding: 2.5px 0px 2.5px 8px;
      color: inherit;
      display: block;
      &:focus {
        text-decoration: none;
      }
      div {
        align-items: center;
      }
    }
  }
  transition: all 0.3s ease;
  &.selected .ant-menu-submenu-title,
  &:hover .ant-menu-submenu-title {
    color: #2f904f !important;
  }
`;

export const CategoryContainer = styled.div`
  position: sticky;
  top: 150px;
  height: fit-content;
  padding-left: 10pt;
  z-index: 10;
`;

interface CategoryNavProps extends RouteComponentProps {
  main?: ContainerState;
}

const FavoriteCategory: FC<CategoryNavProps> = (props) => {
  const { location, main } = props;
  const segment = getPathSegmentFromSlug(location);
  const searchQuery = createSearchQueryfromSlug(location, segment);
  const lang = localStorage.getItem('lang') || 'vi';
  const path = location.pathname === '/favorite' ? '/favorite' : '/market';
  const favoriteCategories = useMemo(() => {
    return main?.favoriteCategories ?? [];
  }, [main, location]);
  const selectedCategory = useMemo(() => {
    const substate = main;
    return getSelectedCategory(searchQuery, substate && substate.favoriteCategories);
  }, [searchQuery, main]);
  return (
    <ErrorBoundary>
      <CategoryContainer className={location.pathname === '/' && 'show-category'}>
        <CategoryWrapper id="favorite-category">
          <CategoryNavContainer>
            <Menu
              getPopupContainer={() => document.getElementById('favorite-category') || document.body}
              style={{ width: 210 }}
            >
              <SubMenu
                key="category-title"
                title={
                  <LabelWrapper className="category-title">
                    <Label>{lang === 'en' ? 'Filter by categories' : 'Lọc theo danh mục'}</Label>
                  </LabelWrapper>
                }
              />
              <SubMenu
                key={ALL_PRODUCTS}
                className={
                  location.pathname !== '/' && !selectedCategory && searchQuery.indexOf('tags') === -1 ? 'selected' : ''
                }
                title={
                  <Link to={path}>
                    <LabelWrapper>
                      {Icons[ALL_PRODUCTS] && <Icon src={Icons[ALL_PRODUCTS]} />}
                      <Label>{lang === 'en' ? ALL_PRODUCTS : 'Tất cả'}</Label>
                    </LabelWrapper>
                  </Link>
                }
              />
              {tags.map((tag) => {
                return (
                  <SubMenu
                    key={tag.name}
                    className={searchQuery.search(tag.value) !== -1 ? 'selected' : ''}
                    title={
                      <Link to={path === '/favorite' ? `${path}?tags=${tag.value}` : `${tag.value}`}>
                        <LabelWrapper>
                          {Icons[tag.name] && <Icon src={Icons[tag.name]} />}
                          <Label>{tag.nameLocal}</Label>
                        </LabelWrapper>
                      </Link>
                    }
                  />
                );
              })}
              {favoriteCategories.map((category: Category, _index) => {
                return (
                  <SubMenu
                    key={category.name}
                    title={
                      <Link
                        to={
                          path === '/favorite'
                            ? `${path}?category=${encodeURIComponent(category.name)}`
                            : `/${generateSlugFromID(category?.id || 0, lang)}`
                        }
                      >
                        <LabelWrapper>
                          {Icons[category.name] && <Icon src={Icons[category.name]} />}
                          <Label>{category.nameLocal}</Label>
                          <Icon src={rightArrow} />
                        </LabelWrapper>
                      </Link>
                    }
                    className={selectedCategory && selectedCategory.name.indexOf(category.name) > -1 ? 'selected' : ''}
                    popupClassName="submenu-list"
                  >
                    {category.children?.map((subCategory) => (
                      <Menu.Item key={subCategory.name} style={{ padding: 0 }}>
                        <SubLabel
                          to={
                            path
                              ? `${path}?category=${encodeURIComponent(subCategory.name)}`
                              : `/${generateSlugFromID(subCategory.id || 0, lang)}`
                          }
                          className={selectedCategory && selectedCategory.name === subCategory.name ? 'selected' : ''}
                        >
                          {subCategory.nameLocal}
                        </SubLabel>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              })}
            </Menu>
          </CategoryNavContainer>
          <CategoryNavShadow />
        </CategoryWrapper>
      </CategoryContainer>
    </ErrorBoundary>
  );
};

export default withRouter(FavoriteCategory);
