export enum RESPONSIVE_IMAGE_SIZES {
  SMALL = 250,
  MEDIUM = 500,
  LARGE = 900,
}
export const CATEGORIES_REGEXP = `/:slug((?!products\/)[\w-\/]+)(-c[\d]{1,5})(\?lang=en)?(\?(?<=\?lang=en&page=\d+)|&page=\d+)?`;
export const suffixCategoriesRegexp = {
  categories: {
    codename: /(-c)[\d]{1,5}/g,
  },
  tags: {
    codename: /^(?!.*-c[\d]{1,5})[A-Z0-9_]+(\?lang=en)?/g,
  },
  page: {
    codename: 'page=',
  },
};
