/**
 *
 * @param slug a series of string to parse into supplierInternalCode
 * @returns get the last character of the split string - especially the supplierInternalCode to get product data
 */
export default function parseSlug(slug: string): string {
  const slugSplitString = slug.split('-');
  const slugStringLength = slugSplitString.length;
  if (!slugStringLength) return '';
  return slugSplitString[slugStringLength - 1];
}
