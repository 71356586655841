import { Category } from 'types/schema';

export const findSubCategoryByID = (categories: Category[], categoryID: number): Category | undefined => {
  for (const category of categories) {
    if (category.children) {
      const found = category.children.find((child) => child.id === categoryID);
      if (found) {
        return found;
      }
      const nestedFound = findSubCategoryByID(category.children, categoryID);
      if (nestedFound) {
        return nestedFound;
      }
    }
  }
  return undefined;
};

export default findSubCategoryByID;
