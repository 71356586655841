import en from './en';
import vi from './vi';
import * as Sentry from '@sentry/browser';

export default function (message) {
  if (!message) {
    return '';
  }
  try {
    let lang = 'vi';
    if (typeof window !== 'undefined' && !!window.localStorage.getItem('lang')) {
      lang = window.localStorage.getItem('lang');
    }
    const translatedMessage = (lang === 'en' ? en[message.id] : vi[message.id]) || message.defaultMessage;
    return translatedMessage;
  } catch (error) {
    console.error(error);
    console.log(message);
    Sentry.withScope(scope => {
      scope.setExtra('message', message);
      Sentry.captureException(error);
    });
  }
}
