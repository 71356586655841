import React, { useMemo } from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styled from 'styled-components';
import routes from './routes';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import SeparatorIcon from './separator.svg';
import qs from 'qs';
import decodeQuery from '../../utils/decodeQuery';
import translations from 'translations';
import messages from './messages';
import selectProductDetailsPage from '../../containers/ProductDetailsPage/selectors';
import { ContainerState } from '../../containers/ProductDetailsPage/types';
import { setBreadCrumbDataMarkup } from '../../containers/MainLayout/actions';
import { createStructuredSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { IRouteBreadCrumb } from './type';
import { Skeleton } from 'antd';
import { pathnameChecker } from 'utils/url';
import selectBrandDetailPage from 'containers/BrandDetailPage/selectors';
import { BrandDetailPageState } from 'containers/BrandDetailPage/types';
import { generateSlugFromID, getCategoryIDFromSlug } from 'utils/urls/categoriesPathSegment';
import { suffixCategoriesRegexp } from 'utils/urls/constants';
import { Category } from 'types/schema';
import findSubCategoryByID from 'utils/findSubCategory';
import getRefinedLink from 'components/PaginationLink/getRefinedLink';

interface StateProps {
  productDetailsPage: ContainerState;
  brandDetailsPage: BrandDetailPageState;
}

const homeRoute = {
  name: 'HomePage',
  nameLocal: translations(messages.home),
  path: '/',
};

const brandsRoute = {
  name: 'Brand',
  nameLocal: translations(messages.brand),
  path: '/tat-ca-thuong-hieu',
};

const Icon = styled(SVGIcon)`
  width: 24pt;
  position: relative;
  top: -1px;
  flex-shrink: 0;
`;
const BreadCrumbWrapper = styled.div`
  max-width: 1200px;
  height: 23px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 0 10pt;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  @media (max-width: 1024px) {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 12pt 8px;
  }
`;
const BreadCrumbItem = styled.li`
  display: flex;
  align-items: center;
  color: #9e9e9e;
  font-size: 14px;
  flex-shrink: 0;
  &:last-child {
    color: #424242;
    cursor: default;
    pointer-events: none;
  }
`;
const BreadCrumbNameLink = styled(Link)`
  color: #9e9e9e;
  font-size: 14px;
  flex-shrink: 0;
  @media (max-width: 992px) {
    &:hover {
      color: #9e9e9e;
    }
  }
`;
const BreadCrumbName = styled.p`
  color: #424242;
  font-size: 14px;
  flex-shrink: 0;
`;
const SkeletonWrapper = styled.div`
  width: 100%;
  .ant-skeleton-paragraph {
    display: none;
  }
  .ant-skeleton-title {
    margin-top: 0 !important;
    width: 50% !important;
  }
`;
const validSearchQueries = ['category', 'tags', 'supplier', 'q', 'page'];

const Breadcrumb = (props) => {
  const {
    main: { categories, favoriteCategories },
    router: { location },
    productDetailsPage,
    brandDetailsPage,
    setBreadCrumbDataMarkup,
  } = props;

  const lang = localStorage.getItem('lang') || 'vi';

  const routesData = useMemo<IRouteBreadCrumb[]>(() => {
    const handleFlattenCategories = (categories: Category[], pathname: string, parentName: string = '') => {
      return categories.reduce((accumulate: IRouteBreadCrumb[], category: Category) => {
        const route: IRouteBreadCrumb = {
          name: category.name,
          nameLocal: category.nameLocal,
          path:
            pathname === 'categories'
              ? `/${generateSlugFromID(category?.id || 0, lang)}`
              : `/${pathname}?category=${encodeURIComponent(category.name)}`,
          parentName: parentName,
          type: pathname,
        };
        accumulate.push(route);
        if ('children' in category) {
          const childrenRoutes = handleFlattenCategories(category.children, pathname, route.name);
          accumulate.push(...childrenRoutes);
        }
        return accumulate;
      }, []);
    };

    /* Flatten the categories tree into an array of single categories elements with 'cate' suffixed codename. */
    const categoriesRoutes = handleFlattenCategories(categories, 'categories');
    const favoriteCategoriesRoutes = handleFlattenCategories(favoriteCategories, 'favorite', 'Favorite');

    /* Return an array of all categories and tags elements */
    return [...routes, ...categoriesRoutes, ...favoriteCategoriesRoutes];
  }, [categories, favoriteCategories]);

  const breadCrumbData = useMemo<IRouteBreadCrumb[]>(() => {
    const { pathname, search } = location;
    const newRoutesData = JSON.parse(JSON.stringify(routesData));
    const querySearchValue = qs.parse(search, {
      ignoreQueryPrefix: true,
      decoder: decodeQuery,
    });
    const generateValidSearchQueriesString = () => {
      const validSearchQueriesObject = Object.keys(querySearchValue).reduce((accumulate, queryName) => {
        if (validSearchQueries.includes(queryName)) {
          return {
            ...accumulate,
            [queryName]: querySearchValue[queryName],
          };
        }
        return accumulate;
      }, {});
      return Object.keys(validSearchQueriesObject).length ? `?${qs.stringify(validSearchQueriesObject)}` : '';
    };
    const uniquePathnameElements = new Set<string>(
      `${pathname}${lang === 'en' ? '?lang=en' : ''}${generateValidSearchQueriesString()}`.split('/').splice(1),
    );
    let pathnameElements: string[] = Array.from(uniquePathnameElements);
    const breadCrumbRoutes: IRouteBreadCrumb[] = [];
    const isProductsPathname = pathnameElements[0] === 'products' && productDetailsPage?.product?.name ? true : false;
    // implement search results breadcrumb
    if ('q' in querySearchValue && pathnameElements[0].includes('market')) {
      breadCrumbRoutes.push({
        name: 'SearchResults',
        nameLocal: `${translations(messages.searchResults)} "${querySearchValue.q}"`,
        path: '',
      });
      setBreadCrumbDataMarkup([homeRoute, ...breadCrumbRoutes]);
      return [homeRoute, ...breadCrumbRoutes];
    }

    // Implement brand detail breadcrumb route
    if (pathnameElements[0] === 'tat-ca-thuong-hieu' && brandDetailsPage?.brandInfo?.name) {
      breadCrumbRoutes.push({
        name: 'BrandDetail',
        nameLocal: brandDetailsPage?.brandInfo?.name,
        path: '',
      });
      return [homeRoute, brandsRoute, ...breadCrumbRoutes];
    }

    // implement product detail breadcrumb route
    if (isProductsPathname) {
      const {
        product: { category },
      } = productDetailsPage;

      const categoryValue = newRoutesData.find(
        (route: IRouteBreadCrumb) =>
          route.name === category.parent.name && suffixCategoriesRegexp.categories.codename.test(route.path),
      );
      const subCategoryName = `${category.parent.name}/${category.name}`;
      const subCategoryValue = newRoutesData.find(
        (route: IRouteBreadCrumb) =>
          route.name === subCategoryName && suffixCategoriesRegexp.categories.codename.test(route.path),
      );
      pathnameElements = [categoryValue?.path.slice(1), subCategoryValue?.path?.slice(1)];
    } else {
      pathnameElements.forEach((element, index, elements) => {
        /* Identify the pathname that includes a codename suffix. */
        if (pathnameChecker.isMarketRoutes(element)) {
          /* Check whether the element includes pagination */

          elements[index] = getRefinedLink(element);
          /* Check whether the element is a subcatgories then adding a codename suffix to categories too */
          const categoryID = getCategoryIDFromSlug(elements[index]);
          const subCategory = findSubCategoryByID(categories, categoryID);
          if (subCategory) {
            elements[index] = generateSlugFromID(subCategory?.id || 0, lang);
          }
        }
      });
    }
    const getParentCategory = (currentPath: IRouteBreadCrumb) => {
      const targetCategory = newRoutesData.find((route) => route.name === currentPath.parentName);
      if (targetCategory) breadCrumbRoutes.unshift(targetCategory);
      if (targetCategory?.parentName) {
        getParentCategory(targetCategory);
      }
    };
    /* Implement categories breadcrumb route */
    pathnameElements.forEach((path, index, pathArray) => {
      const currentPath = newRoutesData.find((route) => {
        if (route?.type === 'categories' && index === 0 && pathArray[index + 1]?.includes('/')) {
          return false;
        }
        const tagsRegexp = new RegExp(suffixCategoriesRegexp.tags.codename);
        if (tagsRegexp.test(path) && lang !== 'vi') {
          return route.path === `/${path.replace(/\?lang=en/g, '')}`;
        }
        return route.path === `/${getRefinedLink(path)}`;
      });
      if (currentPath) {
        if (currentPath.name === 'UserInformation') {
          currentPath.path = '/user-management';
        }
        if (currentPath.name === 'GroupInformation') {
          currentPath.path = '/group-management';
        }
        breadCrumbRoutes.push(currentPath);
        if (currentPath?.parentName) {
          getParentCategory(currentPath);
          if (productDetailsPage?.product?.name && pathnameChecker.isProductPage(pathname)) {
            breadCrumbRoutes.push({
              name: 'ProductDetail',
              nameLocal: productDetailsPage?.product?.name,
              path: '',
            });
          }
        }
      } else if (breadCrumbRoutes[index - 1] && 'childrenName' in breadCrumbRoutes[index - 1]) {
        const childrenRoute = newRoutesData.find((route) => route.name === breadCrumbRoutes[index - 1].childrenName);
        if (childrenRoute) breadCrumbRoutes.push(childrenRoute);
      }
    });
    if (breadCrumbRoutes.length) {
      setBreadCrumbDataMarkup([homeRoute, ...breadCrumbRoutes]);
    }
    return [homeRoute, ...breadCrumbRoutes];
  }, [location, categories, favoriteCategories, productDetailsPage, brandDetailsPage]);
  return (
    <BreadCrumbWrapper>
      <>
        {productDetailsPage?.product?.loading === true || brandDetailsPage?.loading === true ? (
          <SkeletonWrapper>
            <Skeleton active />
          </SkeletonWrapper>
        ) : (
          breadCrumbData.map((item, index) => (
            <BreadCrumbItem key={item.path}>
              {breadCrumbData.length - 1 !== index ? (
                <BreadCrumbNameLink to={item.path}>{item.nameLocal}</BreadCrumbNameLink>
              ) : (
                <BreadCrumbName>{item.nameLocal}</BreadCrumbName>
              )}
              {breadCrumbData.length - 1 !== index && <Icon src={SeparatorIcon} />}
            </BreadCrumbItem>
          ))
        )}
      </>
    </BreadCrumbWrapper>
  );
};
const mapStateToProps = createStructuredSelector<ApplicationRootState, StateProps>({
  productDetailsPage: selectProductDetailsPage(),
  brandDetailsPage: selectBrandDetailPage(),
});
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setBreadCrumbDataMarkup: (payload: IRouteBreadCrumb[]) => dispatch(setBreadCrumbDataMarkup(payload)),
  };
};
export default compose(
  connect((state) => state),
  connect(mapStateToProps, mapDispatchToProps),
)(Breadcrumb);
