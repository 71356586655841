import { BrandProduct, ContainerActions, ContainerState, SimilarBrand } from './types';
import ActionTypes from './constants';
import MainActionTypes from 'containers/MainLayout/constants';

const loadingResources = Array(20)
  .fill(0)
  .map<BrandProduct>((v, i) => ({
    loading: true,
    id: String(i),
    price: 0,
    originalPrice: 0,
    tags: [],
    isFavorite: false,
  }));

const loadingSimilarBrands = Array(5)
  .fill(0)
  .map<SimilarBrand>((v, i) => ({
    loading: true,
    id: String(i),
  }));

export const initialState: ContainerState = {
  loading: false,
  products: loadingResources,
  noteItemLoading: false,
  categories: [],
  similarBrands: loadingSimilarBrands,
  brandInfo: {
    id: '',
    logo: '',
    name: '',
    description: '',
  },
};

function brandDetailsReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.BRAND_DETAIL_REQUEST:
      return { ...state, loading: true };

    case ActionTypes.BRAND_DETAIL_SUCCESS:
      return { ...state, loading: false, categories: payload.categories, brandInfo: payload.brandInfo };

    case ActionTypes.BRAND_DETAIL_FAILURE: {
      return { ...state, loading: false };
    }
    case MainActionTypes.FAVORITE_SUCCESS:
      const updatedProducts = [...state.products];

      const productIndex = updatedProducts.findIndex((p) => p.id === payload.id);

      if (productIndex > -1) {
        updatedProducts[productIndex] = { ...updatedProducts[productIndex], isFavorite: payload.favorite };
      }
      return {
        ...state,
        products: updatedProducts,
      };

    case MainActionTypes.SET_PRODUCT_NOTE_REQUEST:
      return { ...state, noteItemLoading: true };

    case MainActionTypes.SET_PRODUCT_NOTE_SUCCESS:
      const updateNoteProducts = [...state.products];
      const updateNoteProductIndex = updateNoteProducts.findIndex((p) => p.id === payload.id);
      if (updateNoteProductIndex > -1) {
        updateNoteProducts[updateNoteProductIndex] = {
          ...updateNoteProducts[updateNoteProductIndex],
          note: payload.note,
        };
      }
      return { ...state, products: updateNoteProducts, noteItemLoading: false };
    case MainActionTypes.SET_PRODUCT_NOTE_FAILURE:
      return { ...state, noteItemLoading: false };

    case ActionTypes.FETCH_BRAND_PRODUCTS_REQUEST:
      return { ...state, products: loadingResources };
    case ActionTypes.FETCH_BRAND_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: payload,
      };

    case ActionTypes.FETCH_SIMILAR_BRANDS_SUCCESS:
      return {
        ...state,
        similarBrands: payload,
      };
    default:
      return state;
  }
}

export default brandDetailsReducer;
