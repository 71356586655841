enum ActionTypes {
  APPLY_FILTER = 'app/BrandDetail/APPLY_FILTER',
  BRAND_DETAIL_REQUEST = 'app/BrandDetail/BRAND_DETAIL_REQUEST',
  BRAND_DETAIL_SUCCESS = 'app/BrandDetail/BRAND_DETAIL_SUCCESS',
  BRAND_DETAIL_FAILURE = 'app/BrandDetail/BRAND_DETAIL_FAILURE',
  FETCH_BRAND_PRODUCTS_REQUEST = 'app/BrandDetail/FETCH_BRAND_PRODUCTS_REQUEST',
  FETCH_BRAND_PRODUCTS_SUCCESS = 'app/BrandDetail/FETCH_BRAND_PRODUCTS_SUCCESS',
  FETCH_BRAND_PRODUCTS_FAILURE = 'app/BrandDetail/FETCH_BRAND_PRODUCTS_FAILURE',
  FETCH_SIMILAR_BRANDS_REQUEST = 'app/BrandDetail/FETCH_SIMILAR_BRANDS_REQUEST',
  FETCH_SIMILAR_BRANDS_SUCCESS = 'app/BrandDetail/FETCH_SIMILAR_BRANDS_SUCCESS',
  FETCH_SIMILAR_BRANDS_FAILURE = 'app/BrandDetail/FETCH_SIMILAR_BRANDS_FAILURE',
}

export default ActionTypes;
