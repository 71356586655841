import { pathnameChecker } from './url';

const setLanguage = (lang: string) => {
  window.localStorage.setItem('lang', lang);
};

const checkReferrerForMarketPage = () => {
  if (!document.referrer) {
    setLanguage('vi');
    return;
  }

  // const referrerUrl = new URL(document.referrer);
  // if (!suffixCategoriesRegexp.categories.codename.test(referrerUrl.pathname)) {
  //   setLanguage('vi');
  // }
};

const setLanguageInLocalStorage = (search: { lang?: string }, pathname: string) => {
  if (search.lang) {
    setLanguage(search.lang.slice(0, 2));
  } else {
    if (pathnameChecker.isProductPage(pathname)) {
      setLanguage('vi');
    } else if (pathnameChecker.isCategoryPage(pathname)) {
      checkReferrerForMarketPage();
    }
  }
};

export default setLanguageInLocalStorage;
