import parseSlug from 'utils/parseSlug';
import categoriesSlug from '../../../categoriesSlug/categoriesSlug.json';
import { suffixCategoriesRegexp } from './constants';

export const getQueryNameFromSlug = (categoryID: number) => {
  if (!categoryID) return '';
  const targetCategory = categoriesSlug.data[categoryID.toString()];
  return targetCategory ? targetCategory.queryName : '';
};

export const getCategoryIDFromSlug = (slug: string) => {
  const categoryCodeName = `-${parseSlug(slug)}`;
  const codename = categoryCodeName?.match(suffixCategoriesRegexp.categories.codename)?.[0];
  const categoryID = codename ? parseInt(codename.slice(2), 10) : 0;
  return categoryID;
};

export const generateSlugFromID = (categoryID: number, lang: string) => {
  if (!categoryID) return '';
  const targetCategory = categoriesSlug.data[categoryID];
  return targetCategory ? targetCategory.name[lang] : '';
};

export const generateSlugFromQueryName = (queryName: string, lang: string) => {
  const targetCategory = Object.values(categoriesSlug.data).find((item) => item.queryName === queryName);
  if (targetCategory) {
    return targetCategory?.name[lang];
  }
  return '';
};
