import { pathnameChecker } from './url';
import createSinglePathSegment from './urls/createSinglePathSegment';

const SEGMENT_PARAMETERS = ['category', 'tags'];

export const getPathSegmentFromSlug = (location: any) => {
  const segmentObject = { category: '', tags: '', page: '' };
  if (location?.pathname && pathnameChecker.isProductPage(location?.pathname)) return segmentObject;

  const pathname = location?.pathname?.slice(1);

  segmentObject.category = createSinglePathSegment(pathname, 'category');
  segmentObject.tags = createSinglePathSegment(pathname, 'tags');
  segmentObject.page = createSinglePathSegment(location.search, 'page');

  return segmentObject;
};

export const createSearchQueryfromSlug = (location: any, segment: Record<string, string | number>) => {
  const categoryQueryString = SEGMENT_PARAMETERS.reduce((qs, segmentName) => {
    const segmentValue = segment[segmentName];
    if (segmentValue) {
      return qs.concat(`&${segmentName}=${segmentName !== 'page' ? encodeURIComponent(segmentValue) : segmentValue}`);
    }
    return qs;
  }, '');
  return location.search && !location.search.includes('?lang=')
    ? `${location.search}${categoryQueryString}`
    : `?${segment.page && `page=${segment.page}`}${categoryQueryString}`;
};
