import { getCategoryIDFromSlug, getQueryNameFromSlug } from './categoriesPathSegment';
import { suffixCategoriesRegexp } from './constants';

const createSinglePathSegment = (pathname: string, segment: string) => {
  switch (segment) {
    case 'tags': {
      const tagsRegexp = new RegExp(suffixCategoriesRegexp.tags.codename);
      return tagsRegexp.test(pathname)
        ? pathname?.match(suffixCategoriesRegexp.tags.codename)?.[0]
        : '';
    }

    case 'category': {
      // const codename = pathname?.match(suffixCategoriesRegexp.categories.codename)?.[0];
      // const categoryID = codename ? parseInt(codename.slice(2), 10) : 0;
      const categoryID = getCategoryIDFromSlug(pathname);
      return categoryID ? getQueryNameFromSlug(categoryID) : '';
    }
    case 'page':
      return pathname?.lastIndexOf(suffixCategoriesRegexp.page.codename) !== -1
        ? pathname?.slice(
          pathname.lastIndexOf(suffixCategoriesRegexp.page.codename) + suffixCategoriesRegexp.page.codename.length,
        )
        : '';
    default:
      return '';
  }
};

export default createSinglePathSegment;
