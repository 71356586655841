import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectBrandDetailPageDomain = (state: ApplicationRootState) => state.brandDetailPage || initialState;

const selectBrandDetailPage = () => createSelector(selectBrandDetailPageDomain, (substate) => substate);

const selectLoading = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.loading);
const selectProducts = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.products);
const selectSimilarBrands = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.similarBrands);
const selectNoteItemLoading = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.noteItemLoading);
const selectBrandInfo = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.brandInfo);
const selectCategories = () => createSelector(selectBrandDetailPageDomain, (substate) => substate.categories);
export default selectBrandDetailPage;
export {
  selectBrandDetailPageDomain,
  selectLoading,
  selectProducts,
  selectNoteItemLoading,
  selectSimilarBrands,
  selectBrandInfo,
  selectCategories,
};
