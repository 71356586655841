import React, { memo, useCallback } from 'react';
import Banner from '../Banner';
import match from 'utils/match';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { BannerItemUnion, GoToScreenBannerItem, ShowPopupBannerItem, ShowWebViewBannerItem } from 'types/schema';

interface IProps extends RouteComponentProps {
  banner: BannerItemUnion;
  onGoToPage?: (banner: GoToScreenBannerItem) => void;
  onOpenPopup?: (popupData: ShowPopupBannerItem | ShowWebViewBannerItem) => void;
}

const BannerWidget = (props: IProps) => {
  const { banner, onOpenPopup, history } = props;
  const onClickGoToPage = useCallback(() => {
    const bannerGotoPageItem = banner as GoToScreenBannerItem;
    history.push(`/${(bannerGotoPageItem.screen.filter.tags || '').toString()}`);
  }, [banner, history]);

  const onClickOpenPopup = useCallback(() => {
    const bannerPopupContent: ShowPopupBannerItem = banner as ShowPopupBannerItem;
    if (onOpenPopup) onOpenPopup(bannerPopupContent);
  }, [banner, onOpenPopup]);

  const onClickOpenIframePopup = useCallback(() => {
    const bannerPopupIframeContent = banner as ShowPopupBannerItem;
    if (onOpenPopup) onOpenPopup(bannerPopupIframeContent);
  }, [banner, history]);

  return (
    <>
      {match(banner, {
        GoToScreenBannerItem: () => {
          return <Banner imageUrl={banner.imageUrl} onClick={onClickGoToPage} />;
        },
        ShowPopupBannerItem: () => <Banner imageUrl={banner.imageUrl} onClick={onClickOpenPopup} />,
        ShowWebViewBannerItem: (banner: ShowWebViewBannerItem) => (
          <Banner imageUrl={banner.imageUrl} onClick={onClickOpenIframePopup} />
        ),
      })}
    </>
  );
};

export default withRouter(memo(BannerWidget));
