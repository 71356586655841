import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import styled from 'styled-components';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import subBackground from './subBackground.svg';
import mobileSubBackground from './mobileSubBackground.svg';
import { Tooltip } from 'antd';

import translations from 'translations';
import messages from '../../utils/messages';
import { Viewport } from 'components/ViewportProvider';
import datlatVeggies from './datlatVeggies.svg';
import combo from './combo.svg';
import gyomuSuper from './gyomuSuper.png';
import kamereoLabelImg from './kamereoLabelImg.png';
import commingSoon from './comingSoon.png';
import './tooltip.css';

interface IProps extends RouteComponentProps {}

interface ISection {
  id: number;
  img?: number;
  svgImg?: any;
  backgroundColor: {
    main: string;
    sub: string;
  };
  title: {
    main: string;
    sub?: string;
  };
  imgStyle?: {
    [key: string]: any;
  };
  tag?: string;
  pageUrl?: string;
  disable?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10pt;
  overflow: auto;
  @media (max-width: 1023px) {
    padding-bottom: 12px;
  }
`;

const TagItem = styled.div`
  position: relative;
  ${({ backgroundcolor }) => `
    background: ${backgroundcolor}
    `}
  @media(min-width: 992px) {
    height: 44px;
    min-width: 228px;
    flex-shrink: 0;
  }
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: space-between;
  min-width: 152px;
  cursor: pointer;
  ${({ disable }) =>
    disable &&
    `
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0.85;
    background: rgba(255, 255, 255, 0.6);
    pointer-events: none;
  }
  cursor: default;
  ${TitleBox} {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${SubTitle} {
      color: #808289;
      font-size: 14px;
      font-weight: 400;
    }
  }
  `}
`;

const TitleBox = styled.div`
  padding: 0 0 0 8px;
  z-index: 10;
  display: flex;
  align-items: center;
  @media (max-width: 991px) {
    width: 106px;
    padding: 8px 0 0 8px;
    align-items: flex-start;
    flex-direction: column;
  }
`;
const MainTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;
const SubTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
`;

const SubBackground = styled(SVGIcon)`
  @media (max-width: 991px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  ${({ backgroundcolor }) => `
    rect {
      fill: ${backgroundcolor}
    }
  `}
`;
const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 15px;
  display: flex;
  @media (max-width: 991px) {
    ${({ right }) => `
      right: ${right ?? 15}px;
    `}
  }
  ${({ bottom }) => bottom && `bottom: ${bottom}px`}
`;
const Image = styled.img`
  width: 60px;
`;
const SvgImage = styled(SVGIcon)``;

const CommingSoonPopup = styled.div`
  width: 380px;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const CommingSoonImage = styled.img`
  width: 40px;
  height: 40px;
`;

const CommingSoonTextbox = styled.div`
  color: #242527;
  font-weight: 400;
  line-height: 16px;
  & > div:first-child {
    font-weight: 500;
    margin-bottom: 2px;
  }
`;
const CommingSoonText = styled.div``;

const TagNavBar: FC<IProps> = ({ history }) => {
  const sectionData: ISection[] = [
    {
      id: 1,
      img: kamereoLabelImg,
      backgroundColor: {
        main: '#E9F2FF',
        sub: '#CCE0FF',
      },
      title: {
        main: translations(messages.kamereoBrand),
        sub: translations(messages.kamereoBrandSubText),
      },
      imgStyle: {
        right: 5,
      },
      pageUrl: 'thuong-hieu-kamereo',
    },
    {
      id: 2,
      svgImg: combo,
      backgroundColor: {
        main: '#FFECEA',
        sub: '#FFD5D2',
      },
      title: {
        main: translations(messages.combo),
        sub: translations(messages.comboSubText),
      },
      tag: 'SUPER_COMBO',
      imgStyle: {
        right: -24,
      },
    },
    {
      id: 3,
      svgImg: datlatVeggies,
      backgroundColor: {
        main: '#EFFFD6',
        sub: '#D3F1A7',
      },
      title: {
        main: translations(messages.dalatVeggies),
        sub: translations(messages.dalatVeggiesSubText),
      },
      tag: 'RAU_CU_QUA_DA_LAT',
      imgStyle: {
        right: 5,
      },
    },
    {
      id: 4,
      img: gyomuSuper,
      backgroundColor: {
        main: '#E7F9FF',
        sub: '#C6EDFB',
      },
      title: {
        main: translations(messages.gyomuSuper),
        sub: translations(messages.commingSoon),
      },
      imgStyle: {
        right: -20,
        bottom: 12,
      },
      tag: 'GYOMU_SUPER',
      disable: true,
    },
  ];
  return (
    <Container className="tags-nav-bar-container">
      <Viewport.Consumer>
        {({ width }) => {
          const notDesktop = width < 992;
          return sectionData
            .filter((section) => (notDesktop ? section.id !== 4 : true))
            .map((item) => (
              <Tooltip
                key={item.id}
                placement="bottomRight"
                title={
                  !!item.disable && (
                    <CommingSoonPopup>
                      <CommingSoonImage src={commingSoon} />
                      <CommingSoonTextbox>
                        <CommingSoonText>{translations(messages.commingSoon)}!</CommingSoonText>
                        <CommingSoonText>{translations(messages.commingSoonMessage)}</CommingSoonText>
                      </CommingSoonTextbox>
                    </CommingSoonPopup>
                  )
                }
                overlayClassName={item.disable ? 'coming-soon-tooltip-container' : ''}
              >
                <TagItem
                  backgroundcolor={item.backgroundColor.main}
                  onClick={() => !item?.disable && history.push(`/${item.tag ? item.tag : item.pageUrl}`)}
                  disable={item?.disable}
                >
                  <TitleBox>
                    <MainTitle>{item.title.main}</MainTitle>
                    {(item.id === 4 || notDesktop) && <SubTitle>{item.title.sub}</SubTitle>}
                  </TitleBox>
                  <SubBackground
                    src={notDesktop ? mobileSubBackground : subBackground}
                    backgroundcolor={item.backgroundColor.sub}
                    renderAsImg={false}
                  />
                  <ImageWrapper right={item?.imgStyle?.right} bottom={item?.imgStyle?.bottom}>
                    {item.svgImg ? <SvgImage src={item.svgImg} /> : item.img && <Image src={item.img} />}
                  </ImageWrapper>
                </TagItem>
              </Tooltip>
            ));
        }}
      </Viewport.Consumer>
    </Container>
  );
};

export default withRouter(TagNavBar);
