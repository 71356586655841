import gql from 'graphql-tag';

export default gql`
  query categories($supplierName: String, $isFavorite: Boolean) {
    categories(supplierName: $supplierName, isFavorite: $isFavorite) {
      id
      nameLocal
      name
      id
      children {
        id
        nameLocal
        name
        id
        parent {
          id
          nameLocal
          name
          id
          children {
            id
            nameLocal
            name
            id
          }
        }
      }
    }
  }
`;
