/* tslint:disable */

import { defineMessages } from 'react-intl';

export const scope = 'app.utils';

export default defineMessages({
  callUs: {
    id: `${scope}.callUs`,
    defaultMessage: 'Gọi chúng tôi',
  },
  collection: {
    id: `${scope}.collection`,
    defaultMessage: 'Bộ sưu tập',
  },
  deliveryAddress: {
    id: `${scope}.deliveryAddress`,
    defaultMessage: 'Địa chỉ giao hàng',
  },
  SUPER_COMBO: {
    id: `${scope}.SUPER_COMBO`,
    defaultMessage: 'Combo Ưu Đãi 🎁',
  },
  VEGETABLE_SUPER_SAVING: {
    id: `${scope}.VEGETABLE_SUPER_SAVING`,
    defaultMessage: '🍋 Rau Củ 10Kg Siêu Tiết Kiệm 🥕💰',
  },
  TORANI: {
    id: `${scope}.TORANI`,
    defaultMessage: 'Torani 🍯🍧',
  },
  CP_PRODUCTS: {
    id: `${scope}.CP_PRODUCTS`,
    defaultMessage: 'Thương Hiệu CP',
  },
  VINAMILK_DALAT_MILK: {
    id: `${scope}.VINAMILK_DALAT_MILK`,
    defaultMessage: 'Sữa Vinamilk & Sữa Dalat Milk 🥛🐄',
  },
  JAPANESE_PRODUCTS: {
    id: `${scope}.JAPANESE_PRODUCTS`,
    defaultMessage: '🇯🇵 Sản Phẩm Nhật Bản 🇯🇵',
  },
  BEST_PRICES: {
    id: `${scope}.BEST_PRICES`,
    defaultMessage: 'Giá Tốt Tuần Này',
  },
  BEST_DEALS: {
    id: `${scope}.BEST_DEALS`,
    defaultMessage: 'Siêu Khuyến Mãi',
  },
  FLASH_SALES: {
    id: `${scope}.FLASH_SALES`,
    defaultMessage: '⚡ Flash Sale 🔥',
  },
  NEW_PRODUCTS: {
    id: `${scope}.NEW_PRODUCTS`,
    defaultMessage: 'Sản Phẩm Mới',
  },
  MEAT_ZERO: {
    id: `${scope}.MEAT_ZERO`,
    defaultMessage: 'Meat Zero 🌱',
  },
  FRUITS: {
    id: `${scope}.FRUITS`,
    defaultMessage: 'Trái Cây 🍇🍉',
  },
  LETTUCE: {
    id: `${scope}.LETTUCE`,
    defaultMessage: 'Xà Lách 🥬 🥗',
  },
  RECOMMENDED_FOR_YOU: {
    id: `${scope}.RECOMMENDED_FOR_YOU`,
    defaultMessage: 'Đề xuất cho bạn',
  },
  BOOK_IN_ADVANCE: {
    id: `${scope}.BOOK_IN_ADVANCE`,
    defaultMessage: 'Hàng Đặt Trước 📅✍️',
  },
  DISHWASHING_LIQUID: {
    id: `${scope}.DISHWASHING_LIQUID`,
    defaultMessage: 'Nước rửa chén Kamereo',
  },
  RAU_CU_QUA_TRUNG_QUOC: {
    id: `${scope}.RAU_CU_QUA_TRUNG_QUOC`,
    defaultMessage: 'Rau Củ Quả Trung Quốc 🇨🇳🥦',
  },
  TRAI_CAY_NOI_DIA: {
    id: `${scope}.TRAI_CAY_NOI_DIA`,
    defaultMessage: '🥥 Trái Cây Nội Địa 🍉🍌🍍',
  },
  TRAI_CAY_NHAP_KHAU: {
    id: `${scope}.TRAI_CAY_NHAP_KHAU`,
    defaultMessage: '🍒 Trái Cây Nhập Khẩu 🍒🍎🍇',
  },
  THUONG_HIEU_KAMEREO: {
    id: `${scope}.THUONG_HIEU_KAMEREO`,
    defaultMessage: 'Thương Hiệu Kamereo 🏷️ ',
  },
  NAM_TUOI_NGON: {
    id: `${scope}.NAM_TUOI_NGON`,
    defaultMessage: 'Nấm Tươi Ngon 🍄',
  },
  THAN_THIEN_MOI_TRUONG: {
    id: `${scope}.THAN_THIEN_MOI_TRUONG`,
    defaultMessage: 'Thân Thiện Môi Trường ♻️ 🌿♻️',
  },
  NGUYEN_LIEU_PHA_CHE: {
    id: `${scope}.NGUYEN_LIEU_PHA_CHE`,
    defaultMessage: 'Nguyên Liệu Pha Chế 🍹🍸',
  },
  NGUYEN_LIEU_LAM_BANH: {
    id: `${scope}.NGUYEN_LIEU_LAM_BANH`,
    defaultMessage: '🍰 Nguyên Liệu Làm Bánh 🍰 🧁🥖',
  },
  AJINOMOTO_GIA_TOT: {
    id: `${scope}.AJINOMOTO_GIA_TOT`,
    defaultMessage: 'Sản Phẩm Ajinomoto 🍜',
  },
  HAI_SAN: {
    id: `${scope}.HAI_SAN`,
    defaultMessage: 'Sản Phẩm Hải Sản 🦞🐟',
  },
  HEO_BO_GIA_SUC: {
    id: `${scope}.HEO_BO_GIA_SUC`,
    defaultMessage: 'Sản Phẩm Gia Súc 🥩🍖',
  },
  GA_VIT_GIA_CAM: {
    id: `${scope}.GA_VIT_GIA_CAM`,
    defaultMessage: 'Sản Phẩm Gia Cầm 🍗🐔',
  },
  RAU_CU_QUA_DA_LAT: {
    id: `${scope}.RAU_CU_QUA_DA_LAT`,
    defaultMessage: '🌻 Rau Củ Quả Đà Lạt 🍓🏞️',
  },
  UU_DAI: {
    id: `${scope}.UU_DAI`,
    defaultMessage: 'Ưu Đãi 🎉',
  },
  TOP_PICK: {
    id: `${scope}.TOP_PICK`,
    defaultMessage: 'Lựa Chọn Hàng Đầu ⭐',
  },
  BEST_CHOICE: {
    id: `${scope}.BEST_CHOICE`,
    defaultMessage: 'Lựa Chọn Tốt Nhất 🏆',
  },
  GYOMU_SUPER: {
    id: `${scope}.GYOMU_SUPER`,
    defaultMessage: 'Gyomu Super - Hàng Nhật Nội Địa 🇯🇵📦',
  },
  NHA_HANG_VIET: {
    id: `${scope}.NHA_HANG_VIET`,
    defaultMessage: 'Dành Cho Nhà Hàng Việt 🍜🇻🇳',
  },
  NHA_HANG_TAY: {
    id: `${scope}.NHA_HANG_TAY`,
    defaultMessage: 'Dành Cho Nhà Hàng Tây 🍔🍝',
  },
  NHA_HANG_NHAT_BAN: {
    id: `${scope}.NHA_HANG_NHAT_BAN`,
    defaultMessage: 'Dành Cho Nhà Hàng Nhật Bản 🍣🍱',
  },
  QUAN_AN_HAN_QUOC: {
    id: `${scope}.QUAN_AN_HAN_QUOC`,
    defaultMessage: 'Dành Cho Nhà Hàng Hàn Quốc 🍲🇰🇷',
  },
  NHA_HANG_TRUNG_HOA: {
    id: `${scope}.NHA_HANG_TRUNG_HOA`,
    defaultMessage: 'Dành Cho Nhà Hàng Trung Hoa 🥢🥡',
  },
  QUAN_AN_THAI_LAN: {
    id: `${scope}.QUAN_AN_THAI_LAN`,
    defaultMessage: 'Dành Cho Nhà Hàng Thái Lan 🍛🇹🇭',
  },
  NHA_HANG_CHAY: {
    id: `${scope}.NHA_HANG_CHAY`,
    defaultMessage: 'Dành Cho Nhà Hàng Chay 🥗🥒',
  },
  DANH_CHO_BAR_PUB_CLUB: {
    id: `${scope}.DANH_CHO_BAR_PUB_CLUB`,
    defaultMessage: 'Dành Cho Bar - Pub - Club 🍻🍷',
  },
  DANH_CHO_KHACH_SAN: {
    id: `${scope}.DANH_CHO_KHACH_SAN`,
    defaultMessage: 'Dành Cho Khách Sạn 🏨🛎️',
  },
  CUA_HANG_TIEN_LOI: {
    id: `${scope}.CUA_HANG_TIEN_LOI`,
    defaultMessage: 'Dành Cho Cửa Hàng Tiện Lợi 🛒🏪',
  },
  DANH_CHO_SIEU_THI: {
    id: `${scope}.DANH_CHO_SIEU_THI`,
    defaultMessage: 'Dành Cho Siêu Thị 🛍️🛒',
  },
  CUA_HANG_BAN_LE: {
    id: `${scope}.CUA_HANG_BAN_LE`,
    defaultMessage: 'Dành Cho Cửa Hàng Bán Lẻ 🛍️🏬',
  },
  CUA_HANG_TAP_HOA: {
    id: `${scope}.CUA_HANG_TAP_HOA`,
    defaultMessage: 'Dành Cho Cửa Hàng Tạp Hóa 🍞🥛',
  },
  CUA_HANG_TRUC_TUYEN: {
    id: `${scope}.CUA_HANG_TRUC_TUYEN`,
    defaultMessage: 'Dành Cho Cửa Hàng Trực Tuyến 💻📦',
  },
  CATERING_CANTEEN_CAN_TIN: {
    id: `${scope}.CATERING_CANTEEN_CAN_TIN`,
    defaultMessage: 'Dành Cho Căn Tin 🍽️🥗',
  },
  NHA_MAY_XI_NGHIEP: {
    id: `${scope}.NHA_MAY_XI_NGHIEP`,
    defaultMessage: 'Dành Cho Nhà Máy - Xí Nghiệp 🏭',
  },
  TRUONG_HOC: {
    id: `${scope}.TRUONG_HOC`,
    defaultMessage: 'Dành Cho Trường Học 🎒📚',
  },
  BENH_VIEN: {
    id: `${scope}.BENH_VIEN`,
    defaultMessage: 'Dành Cho Bệnh Viện 🏥💉',
  },
  VAN_PHONG: {
    id: `${scope}.VAN_PHONG`,
    defaultMessage: 'Dành Cho Văn Phòng 🖇️💼',
  },
  PHONG_TAP_GYM: {
    id: `${scope}.PHONG_TAP_GYM`,
    defaultMessage: 'Dành Cho Phòng Tập Gym 🏋️‍♀️🤸‍♂️',
  },
  TRUNG_TAM_SU_KIEN: {
    id: `${scope}.TRUNG_TAM_SU_KIEN`,
    defaultMessage: 'Dành Cho Trung Tâm Sự Kiện 🎫🏟️',
  },
  AN_NHAU: {
    id: `${scope}.AN_NHAU`,
    defaultMessage: 'Dành Cho Quán Ăn Nhậu 🍻🍖',
  },
  QUAN_AN_VIET: {
    id: `${scope}.QUAN_AN_VIET`,
    defaultMessage: 'Dành Cho Quán Ăn Việt 🍜🇻🇳',
  },
  CAO_CAP: {
    id: `${scope}.CAO_CAP`,
    defaultMessage: 'Sản Phẩm Cao Cấp 🥇',
  },
  NHAP_KHAU: {
    id: `${scope}.NHAP_KHAU`,
    defaultMessage: 'Sản Phẩm Nhập Khẩu 🌍✈️',
  },
  HOA_TRANG_TRI: {
    id: `${scope}.HOA_TRANG_TRI`,
    defaultMessage: 'Hoa Trang Trí 🌸 💐🌷',
  },
  RAU_CU_QUA_MIEN_TAY: {
    id: `${scope}.RAU_CU_QUA_MIEN_TAY`,
    defaultMessage: 'Rau Củ Quả Miền Tây 🌾🍠',
  },
  mart: {
    id: `${scope}.mart`,
    defaultMessage: 'Chợ',
  },
  accountCreatedSuccessfully: {
    id: `${scope}.accountCreateSuccessfully`,
    defaultMessage: 'Tài khoản đã được tạo thành công!',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Thông Tin Cơ bản',
  },
  print: {
    id: `${scope}.print`,
    defaultMessage: 'In PDF',
  },
  printing: {
    id: `${scope}.printing`,
    defaultMessage: 'Chúng tôi đang xử lý file PDF. Vui lòng trở lại sau 1 phút.',
  },
  fieldRequired: {
    id: `${scope}.fieldRequired`,
    defaultMessage: '{field} không được để trống.',
  },
  fieldEmail: {
    id: `${scope}.fieldEmail`,
    defaultMessage: 'Vui lòng nhập đúng địa chỉ email!',
  },
  presevationAll: {
    id: `${scope}.presevationAll`,
    defaultMessage: 'Mọi điều kiện',
  },
  presevationRefrigerated: {
    id: `${scope}.presevationRefrigerated`,
    defaultMessage: 'Tủ mát',
  },
  presevationFrozen: {
    id: `${scope}.presevationFrozen`,
    defaultMessage: 'Tủ đông',
  },
  presevationRoomTemperature: {
    id: `${scope}.presevationRoomTemperature`,
    defaultMessage: 'Nhiệt độ phòng',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Tên Đăng Nhập',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Số Điện Thoại',
  },
  orderId: {
    id: `${scope}.orderId`,
    defaultMessage: 'Mã đơn hàng',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Thêm',
  },
  addAll: {
    id: `${scope}.addAll`,
    defaultMessage: 'Thêm tất cả',
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: 'Cập nhật',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Lưu',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Xác nhận',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Đóng',
  },
  readMoreAboutUs: {
    id: `${scope}.readMoreAboutUs`,
    defaultMessage: 'Tìm hiểu thêm về chúng tôi',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Huỷ',
  },
  finish: {
    id: `${scope}.finish`,
    defaultMessage: 'Hoàn tất',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Gửi',
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: 'Quận',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'Thành phố',
  },
  redInvoiceInfo: {
    id: `${scope}.redInvoiceInfo`,
    defaultMessage: 'Thông tin hoá đơn đỏ',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Xoá',
  },
  uploadImage: {
    id: `${scope}.uploadImage`,
    defaultMessage: 'Chọn hình ảnh',
  },
  editInfo: {
    id: `${scope}.editInfo`,
    defaultMessage: 'Chỉnh sửa thông tin',
  },
  taxCode: {
    id: `${scope}.taxCode`,
    defaultMessage: 'Mã số thuế',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Địa chỉ',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Tên',
  },
  fullName: {
    id: `${scope}.fullName`,
    defaultMessage: 'Họ và tên',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Trạng thái',
  },
  uom: {
    id: `${scope}.uom`,
    defaultMessage: 'Đơn vị',
  },
  availability: {
    id: `${scope}.availability`,
    defaultMessage: 'Trạng thái',
  },
  inStock: {
    id: `${scope}.inStock`,
    defaultMessage: 'Còn hàng',
  },
  outStock: {
    id: `${scope}.outStock`,
    defaultMessage: 'Hết hàng',
  },
  origin: {
    id: `${scope}.origin`,
    defaultMessage: 'Xuất xứ',
  },
  preservation: {
    id: `${scope}.preservation`,
    defaultMessage: 'Bảo quản',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Nhà cung cấp',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Danh mục',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Thêm vào giỏ',
  },
  signin: {
    id: `${scope}.signin`,
    defaultMessage: 'Đăng nhập',
  },
  backto: {
    id: `${scope}.backto`,
    defaultMessage: 'Quay lại',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Quay lại',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Gửi',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'email',
  },
  vat: {
    id: `${scope}.vat`,
    defaultMessage: 'VAT',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Hoàn tất!',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Mật Khẩu',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Tên Doanh Nghiệp',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Tên Công Ty',
  },
  emailToReceiveEInvoice: {
    id: `${scope}.emailToReceiveEInvoice`,
    defaultMessage: 'Email để nhận hoá đơn điện tử',
  },
  getVATInvoiceOrNot: {
    id: `${scope}.getVATInvoiceOrNot`,
    defaultMessage: 'Nhận thông tin hoá đơn',
  },
  signup: {
    id: `${scope}.signup`,
    defaultMessage: 'Đăng ký',
  },
  passwordConfirmation: {
    id: `${scope}.passwordConfirmation`,
    defaultMessage: 'nhập lại mật khẩu',
  },
  moq: {
    id: `${scope}.moq`,
    defaultMessage: 'Đơn tối thiểu',
  },
  viewDetail: {
    id: `${scope}.viewDetail`,
    defaultMessage: 'Xem chi tiết',
  },
  supplierName: {
    id: `${scope}.supplierName`,
    defaultMessage: 'Nhà cung cấp',
  },
  underMOQError: {
    id: `${scope}.underMOQError`,
    defaultMessage: 'Đơn hàng dưới giá trị tối thiểu',
  },
  purchased: {
    id: `${scope}.purchased`,
    defaultMessage: 'Thành tiền',
  },
  cartTotal: {
    id: `${scope}.cartTotal`,
    defaultMessage: 'Tổng giỏ hàng',
  },
  cartDetails: {
    id: `${scope}.cartDetails`,
    defaultMessage: 'Giỏ hàng',
  },
  deliveryDetails: {
    id: `${scope}.deliveryDetails`,
    defaultMessage: 'Giao hàng',
  },
  summary: {
    id: `${scope}.summary`,
    defaultMessage: 'Chốt đơn hàng',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Tiếp tục',
  },
  itemImage: {
    id: `${scope}.itemImage`,
    defaultMessage: 'Hình ảnh',
  },
  itemName: {
    id: `${scope}.itemName`,
    defaultMessage: 'sản phẩm',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Giá',
  },
  ward: {
    id: `${scope}.ward`,
    defaultMessage: 'Phường',
  },
  writeANote: {
    id: `${scope}.writeANote`,
    defaultMessage: 'Ghi chú (hoặc lý do bạn muốn thay đổi đơn hàng)',
  },
  unitPrice: {
    id: `${scope}.unitPrice`,
    defaultMessage: 'Đơn giá',
  },
  excludeVAT: {
    id: `${scope}.excludeVAT`,
    defaultMessage: 'không VAT',
  },
  actualPrice: {
    id: `${scope}.actualPrice`,
    defaultMessage: 'Giá',
  },
  includeVAT: {
    id: `${scope}.includeVAT`,
    defaultMessage: 'có VAT',
  },
  deliveredPrice: {
    id: `${scope}.deliveredPrice`,
    defaultMessage: 'Giá đã giao',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Số lượng',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Tổng tiền',
  },
  totalInclVAT: {
    id: `${scope}.totalInclVAT`,
    defaultMessage: 'Tổng tiền (có VAT)',
  },
  numberOfItems: {
    id: `${scope}.numberOfItems`,
    defaultMessage: 'Tổng cộng {count} sản phẩm',
  },
  deleteAllItems: {
    id: `${scope}.deleteAllItems`,
    defaultMessage: 'Bỏ toàn bộ sản phẩm',
  },
  totalItem: {
    id: `${scope}.totalItem`,
    defaultMessage: 'Tổng số sản phẩm',
  },
  items: {
    id: `${scope}.items`,
    defaultMessage: 'sản phẩm',
  },
  earliest: {
    id: `${scope}.earliest`,
    defaultMessage: 'sớm nhất',
  },
  paymentMethod: {
    id: `${scope}.paymentMethod`,
    defaultMessage: 'Cách thanh toán',
  },
  orderNowForEarliestDelivery: {
    id: `${scope}.orderNowForEarliestDelivery`,
    defaultMessage: 'Đặt hàng ngay để được giao hàng sớm nhất',
  },
  noteForDelivery: {
    id: `${scope}.noteForDelivery`,
    defaultMessage: 'Ghi chú giao hàng',
  },
  addNote: {
    id: `${scope}.addNote`,
    defaultMessage: 'Thêm ghi chú',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Ghi chú',
  },
  accountSetting: {
    id: `${scope}.accountSetting`,
    defaultMessage: 'Thiết lập tài khoản',
  },
  signout: {
    id: `${scope}.signout`,
    defaultMessage: 'Đăng xuất',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Đồng ý',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'Không',
  },
  congrats: {
    id: `${scope}.congrats`,
    defaultMessage: 'Chúc mừng',
  },
  deliveryDate: {
    id: `${scope}.deliveryDate`,
    defaultMessage: 'Ngày giao',
  },
  deliveryTime: {
    id: `${scope}.deliveryTime`,
    defaultMessage: 'Lúc',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Tìm kiếm',
  },
  in: {
    id: `${scope}.in`,
    defaultMessage: 'Trong',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Ngày bắt đầu',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Ngày kết thúc',
  },
  removeFavoriteMessage: {
    id: `${scope}.removeFavoriteMessage`,
    defaultMessage: 'Bạn có chắc muốn bỏ sản phẩm này ra khỏi danh mục yêu thích?',
  },
  favorite: {
    id: `${scope}.favorite`,
    defaultMessage: 'Yêu thích?',
  },
  askAgain: {
    id: `${scope}.askAgain`,
    defaultMessage: 'Không hỏi lại',
  },
  sentryError: {
    id: `${scope}.sentryError`,
    defaultMessage: 'Đã có lỗi xảy ra.',
  },
  sentryReportFeedback: {
    id: `${scope}.sentryReportFeedback`,
    defaultMessage: 'Phản hồi cho chúng tôi.',
  },
  sentryTitle: {
    id: `${scope}.sentryTitle`,
    defaultMessage: 'Có lỗi xảy ra.',
  },
  sentrySubtitle: {
    id: `${scope}.sentrySubtitle`,
    defaultMessage: 'Phản hồi cho chúng tôi.',
  },
  sentrySubtitle2: {
    id: `${scope}.sentrySubtitle2`,
    defaultMessage: 'Bạn có thể cho chúng tôi biết chuyện gì đã xảy ra.',
  },
  sentryLabelName: {
    id: `${scope}.sentryLabelName`,
    defaultMessage: 'Tên',
  },
  sentryLabelEmail: {
    id: `${scope}.sentryLabelEmail`,
    defaultMessage: 'Email',
  },
  sentryLabelComments: {
    id: `${scope}.sentryLabelComments`,
    defaultMessage: 'Bạn đã làm gì?',
  },
  sentryLabelClose: {
    id: `${scope}.sentryLabelClose`,
    defaultMessage: 'Đóng',
  },
  sentryLabelSubmit: {
    id: `${scope}.sentryLabelSubmit`,
    defaultMessage: 'Gửi',
  },
  sentryErrorGeneric: {
    id: `${scope}.sentryErrorGeneric`,
    defaultMessage: 'Đã có vấn đề khi gửi lỗi. Thử lại',
  },
  sentryErrorFormEntry: {
    id: `${scope}.sentryErrorFormEntry`,
    defaultMessage: 'Có vài trường không hợp lệ. Bạn cần cập nhật lại.',
  },
  sentrySuccessMessage: {
    id: `${scope}.sentrySuccessMessage`,
    defaultMessage: 'Phản hồi của bạn đã được gửi. Cảm ơn bạn rất nhiều!',
  },
  goToMart: {
    id: `${scope}.goToMart`,
    defaultMessage: 'Vào chợ',
  },
  supplierAll: {
    id: `${scope}.supplierAll`,
    defaultMessage: 'Tất cả nhà cung cấp',
  },
  supplierOrdered: {
    id: `${scope}.supplierOrdered`,
    defaultMessage: 'Chỉ nhà cung cấp có đơn hàng',
  },
  supplierFavorite: {
    id: `${scope}.supplierFavorite`,
    defaultMessage: 'Chỉ nhà cung cấp được yêu thích',
  },
  sameDay: {
    id: `${scope}.sameDay`,
    defaultMessage: 'trong ngày',
  },
  dayBefore: {
    id: `${scope}.dayBefore`,
    defaultMessage: 'trước {n} ngày',
  },
  daysBefore: {
    id: `${scope}.daysBefore`,
    defaultMessage: 'trước {n} ngày',
  },
  COD: {
    id: `${scope}.COD`,
    defaultMessage: 'Thanh toán khi nhận hàng',
  },
  DEBT_15_DAY: {
    id: `${scope}.DEBT_15_DAY`,
    defaultMessage: 'Công nợ 15 ngày',
  },
  DEBT_1_MONTH: {
    id: `${scope}.DEBT_1_MONTH`,
    defaultMessage: 'Công nợ 1 tháng',
  },
  BANK_TRANSFER: {
    id: `${scope}.BANK_TRANSFER`,
    defaultMessage: 'Chuyển khoản',
  },
  DEPOSIT: {
    id: `${scope}.DEPOSIT`,
    defaultMessage: 'Trừ tiền cọc',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'sản phẩm',
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'vi',
  },
  saveSuccess: {
    id: `${scope}.saveSuccess`,
    defaultMessage: 'Lưu thành công',
  },
  saveFailed: {
    id: `${scope}.saveFailed`,
    defaultMessage: 'Lưu thất bại',
  },
  ORDER_PLACE: {
    id: `${scope}.ORDER_PLACE`,
    defaultMessage: 'Tạo đơn hàng',
  },
  ORDER_ITEM_SET_PRICE: {
    id: `${scope}.ORDER_ITEM_SET_PRICE`,
    defaultMessage: 'Cập nhật giá',
  },
  ORDER_ITEM_SET_QUANTITY: {
    id: `${scope}.ORDER_ITEM_SET_QUANTITY`,
    defaultMessage: 'Cập nhật số lượng',
  },
  ORDER_APPROVE: {
    id: `${scope}.ORDER_APPROVE`,
    defaultMessage: 'Duyệt đơn hàng',
  },
  ORDER_ACCEPT: {
    id: `${scope}.ORDER_ACCEPT`,
    defaultMessage: 'Chấp nhận đơn hàng',
  },
  ORDER_CANCEL: {
    id: `${scope}.ORDER_CANCEL`,
    defaultMessage: 'Huỷ đơn hàng',
  },
  ORDER_DELIVERY_CONFIRM: {
    id: `${scope}.ORDER_DELIVERY_CONFIRM`,
    defaultMessage: 'Xác nhận đơn hàng',
  },
  ORDER_VIEW: {
    id: `${scope}.ORDER_VIEW`,
    defaultMessage: 'Xem chi tiết đơn hàng',
  },
  ORDER_LIST: {
    id: `${scope}.ORDER_LIST`,
    defaultMessage: 'Xem danh sách đơn hàng',
  },
  BUYER_FAVORITE_ITEM_ADD: {
    id: `${scope}.BUYER_FAVORITE_ITEM_ADD`,
    defaultMessage: 'Thêm sản phẩm vào yêu thích',
  },
  BUYER_FAVORITE_ITEM_LIST: {
    id: `${scope}.BUYER_FAVORITE_ITEM_LIST`,
    defaultMessage: 'Xem danh sách sản phẩm yêu thích',
  },
  BUYER_USER_ADD: {
    id: `${scope}.BUYER_USER_ADD`,
    defaultMessage: 'Thêm nhân viên',
  },
  BUYER_USER_LIST: {
    id: `${scope}.BUYER_USER_LIST`,
    defaultMessage: 'Xem danh sách nhân viên',
  },
  BUYER_GROUP_ADD: {
    id: `${scope}.BUYER_GROUP_ADD`,
    defaultMessage: 'Tạo nhóm',
  },
  BUYER_GROUP_EDIT: {
    id: `${scope}.BUYER_GROUP_EDIT`,
    defaultMessage: 'Cập nhận nhóm',
  },
  BUYER_GROUP_LIST: {
    id: `${scope}.BUYER_GROUP_LIST`,
    defaultMessage: 'Xem danh sách nhóm',
  },

  ORDER_PLACE_SUB: {
    id: `${scope}.ORDER_PLACE_SUB`,
    defaultMessage: 'Thêm sản phẩm vào giỏ hàng và tao đơn hàng',
  },
  ORDER_ITEM_SET_PRICE_SUB: {
    id: `${scope}.ORDER_ITEM_SET_PRICE_SUB`,
    defaultMessage: 'Cập nhật giá',
  },
  ORDER_ITEM_SET_QUANTITY_SUB: {
    id: `${scope}.ORDER_ITEM_SET_QUANTITY_SUB`,
    defaultMessage: 'Cập nhật số lượng',
  },
  ORDER_APPROVE_SUB: {
    id: `${scope}.ORDER_APPROVE_SUB`,
    defaultMessage: 'Duyệt đơn hàng',
  },
  ORDER_ACCEPT_SUB: {
    id: `${scope}.ORDER_ACCEPT_SUB`,
    defaultMessage: 'Chấp nhận đơn hàng',
  },
  ORDER_CANCEL_SUB: {
    id: `${scope}.ORDER_CANCEL_SUB`,
    defaultMessage: 'Huỷ đơn hàng',
  },
  ORDER_DELIVERY_CONFIRM_SUB: {
    id: `${scope}.ORDER_DELIVERY_CONFIRM_SUB`,
    defaultMessage: 'Xác nhận đơn hàng',
  },
  ORDER_VIEW_SUB: {
    id: `${scope}.ORDER_VIEW_SUB`,
    defaultMessage: 'Xem chi tiết đơn hàng',
  },
  ORDER_LIST_SUB: {
    id: `${scope}.ORDER_LIST_SUB`,
    defaultMessage: 'Xem danh sách đơn hàng',
  },
  BUYER_FAVORITE_ITEM_ADD_SUB: {
    id: `${scope}.BUYER_FAVORITE_ITEM_ADD_SUB`,
    defaultMessage: 'Thêm sản phẩm vào yêu thích',
  },
  BUYER_FAVORITE_ITEM_LIST_SUB: {
    id: `${scope}.BUYER_FAVORITE_ITEM_LIST_SUB`,
    defaultMessage: 'Xem danh sách sản phẩm yêu thích',
  },
  BUYER_USER_ADD_SUB: {
    id: `${scope}.BUYER_USER_ADD_SUB`,
    defaultMessage: 'Thêm nhân viên',
  },
  BUYER_USER_LIST_SUB: {
    id: `${scope}.BUYER_USER_LIST_SUB`,
    defaultMessage: 'Xem danh sách nhân viên',
  },
  BUYER_GROUP_ADD_SUB: {
    id: `${scope}.BUYER_GROUP_ADD_SUB`,
    defaultMessage: 'Tạo nhóm',
  },
  BUYER_GROUP_EDIT_SUB: {
    id: `${scope}.BUYER_GROUP_EDIT_SUB`,
    defaultMessage: 'Cập nhận nhóm',
  },
  BUYER_GROUP_LIST_SUB: {
    id: `${scope}.BUYER_GROUP_LIST_SUB`,
    defaultMessage: 'Xem danh sách nhóm',
  },
  deleteFailed: {
    id: `${scope}.deleteFailed`,
    defaultMessage: 'Delete failed',
  },
  deleteSuccess: {
    id: `${scope}.deleteSuccess`,
    defaultMessage: 'Delete success',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Chỉnh sửa',
  },
  approve: {
    id: `${scope}.approve`,
    defaultMessage: 'Duyệt',
  },
  decline: {
    id: `${scope}.decline`,
    defaultMessage: 'Không duyệt',
  },
  selectAll: {
    id: `${scope}.selectAll`,
    defaultMessage: 'Chọn tất cả',
  },
  priceExclVat: {
    id: `${scope}.priceExclVat`,
    defaultMessage: 'Giá (không VAT)',
  },
  decimalWithOneNumber: {
    id: `${scope}.decimalWithOneNumber`,
    defaultMessage: 'Chúng tôi chỉ hỗ trợ số nhị phân 1 chữ số',
  },
  newTag: {
    id: `${scope}.newTag`,
    defaultMessage: 'Mới',
  },
  bookInAdvanceTag: {
    id: `${scope}.bookInAdvanceTag`,
    defaultMessage: 'Đặt Trước',
  },
  bookInAdvanceDescription: {
    id: `${scope}.bookInAdvanceDescription`,
    defaultMessage: 'Sản phẩm này sẽ được giao khi có hàng. Vui lòng kiểm tra mô tả để biết ngày dự kiến nhận hàng',
  },
  titleRemoveNote: {
    id: `${scope}.titleRemoveNote`,
    defaultMessage: 'Bạn muốn xoá ghi chú cho sản phẩm này?',
  },
  noteRemoveNote: {
    id: `${scope}.noteRemoveNote`,
    defaultMessage: 'Hành động này không thể hoàn tác.',
  },
  addItemNoteSuccess: {
    id: `${scope}.addItemNoteSuccess`,
    defaultMessage: 'Thêm ghi chú thành công',
  },
  deleteItemNoteSuccess: {
    id: `${scope}.deleteItemNoteSuccess`,
    defaultMessage: 'Xoá ghi chú thành công',
  },
  addItemNoteFailed: {
    id: `${scope}.addItemNoteFailed`,
    defaultMessage: 'Thêm ghi chú thất bại',
  },
  deleteItemNoteFailed: {
    id: `${scope}.deleteItemNoteFailed`,
    defaultMessage: 'Xoá ghi chú thất bại',
  },
  remindNotificationPermission: {
    id: `${scope}.remindNotificationPermission`,
    defaultMessage: 'Đừng bỏ lỡ những cập nhật mới nhất từ Kamereo, hãy bật thông báo ở đây!',
  },
  seeAll: {
    id: `${scope}.seeAll`,
    defaultMessage: 'Xem tất cả',
  },
  today: {
    id: `${scope}.today`,
    defaultMessage: 'Hôm nay',
  },
  yesterday: {
    id: `${scope}.yesterday`,
    defaultMessage: 'Hôm qua',
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: 'Lịch sử đơn hàng',
  },
  shop: {
    id: `${scope}.shop`,
    defaultMessage: 'Chợ',
  },
  ordersMobileDisplay: {
    id: `${scope}.ordersMobileDisplay`,
    defaultMessage: 'Đơn hàng',
  },
  accountMobileDisplay: {
    id: `${scope}.accountMobileDisplay`,
    defaultMessage: 'Tài khoản',
  },
  orderHistory: {
    id: `${scope}.orderHistory`,
    defaultMessage: 'Lịch sử đơn hàng',
  },
  businessInformation: {
    id: `${scope}.businessInformation`,
    defaultMessage: 'Thông tin công ty',
  },
  businessSettings: {
    id: `${scope}.businessSettings`,
    defaultMessage: 'Thiết lập doanh nghiệp',
  },
  overviewReport: {
    id: `${scope}.overviewReport`,
    defaultMessage: 'Báo cáo tổng quan',
  },
  backToHome: {
    id: `${scope}.backToHome`,
    defaultMessage: 'Trờ về trang chủ',
  },
  notification: {
    id: `${scope}.notification`,
    defaultMessage: 'Thông báo',
  },
  productDetailImage: {
    id: `${scope}.productDetailImage`,
    defaultMessage: 'Hình chi tiết sản phẩm',
  },
  searchResult: {
    id: `${scope}.searchResult`,
    defaultMessage: 'Kết quả tìm kiếm',
  },
  howDidYouHearUs: {
    id: `${scope}.howDidYouKnowUs`,
    defaultMessage: 'Bạn biết đến Kamereo từ đâu?',
  },
  otherWebsite: {
    id: `${scope}.otherWebsite`,
    defaultMessage: 'Website khác (tin tức/ diễn đàn…)',
  },
  kamereoSalesman: {
    id: `${scope}.kamereoSalesman`,
    defaultMessage: 'Nhân viên của Kamereo',
  },
  fromAcquaintance: {
    id: `${scope}.fromAcquaintance`,
    defaultMessage: 'Từ Bạn bè/ Đồng nghiệp/ Người quen',
  },
  OOHAds: {
    id: `${scope}.OOHAds`,
    defaultMessage: 'Quảng cáo ngoài trời (Quảng cáo trên xe shipper…)',
  },
  offlineEvent: {
    id: `${scope}.offlineEvent`,
    defaultMessage: 'Sự kiện offline',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Khác',
  },
  acquisitionSourceValidate: {
    id: `${scope}.acquisitionSourceValidate`,
    defaultMessage: 'Chia sẻ của bạn giúp chúng tôi cải thiện dịch vụ.',
  },
  otherAcquisitionSourceValidate: {
    id: `${scope}.otherAcquisitionSourceValidate`,
    defaultMessage: 'Vui lòng ghi rõ bạn đã biết đến Kamereo từ đâu.',
  },
  acquisitionSourcePlaceHolder: {
    id: `${scope}.acquisitionSourcePlaceHolder`,
    defaultMessage: 'Vui lòng lựa chọn.',
  },
  sameDayDelivery: {
    id: `${scope}.sameDayDelivery`,
    defaultMessage: 'Giao hàng trong ngày',
  },
  sameDayDeliveryTitle: {
    id: `${scope}.sameDayDeliveryTitle`,
    defaultMessage: 'Giao hàng trong ngày',
  },
  iUnderstand: {
    id: `${scope}.iUnderstand`,
    defaultMessage: 'Tôi đã hiểu',
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Chú ý',
  },
  changeDay: {
    id: `${scope}.changeDay`,
    defaultMessage: 'Đổi ngày',
  },
  confirmSamDayDelivery: {
    id: `${scope}.confirmSamDayDelivery`,
    defaultMessage: 'Các đơn hàng giao trong ngày không cam kết về số lượng hàng hóa cũng như thời gian giao hàng.',
  },
  orderCutOffTime: {
    id: `${scope}.orderCutOffTime`,
    defaultMessage: 'Thời gian chốt đơn hàng'
  },
  deliveryTimeSameDay: {
    id: `${scope}.deliveryTimeSameDay`,
    defaultMessage: 'Thời gian giao hàng'
  },
  fullfillment: {
    id: `${scope}.fullfillment`,
    defaultMessage: 'Số lượng sản phẩm'
  },
  orderCutOffTimeContent: {
    id: `${scope}.orderCutOffTimeContent`,
    defaultMessage: 'Đặt đơn trước 10:00 sáng để có thể chọn giao hàng trong ngày.'
  },
  deliveryTimeSameDayContent: {
    id: `${scope}.deliveryTimeSameDayContent`,
    defaultMessage: 'Thời gian giao hàng sớm nhất là 3 tiếng từ khi đơn hàng được tạo.'
  },
  fullfillmentContent: {
    id: `${scope}.fullfillmentContent`,
    defaultMessage: 'Tỉ lệ giao đủ hàng của đơn giao trong cùng ngày sẽ phụ thuộc vào số lượng đặt hàng và số lượng tồn kho hiện tại.'
  },
  reloadPageMessage: {
    id: `${scope}.reloadPageMessage`,
    defaultMessage: 'Phiên bản mới của trang web chúng tôi đã sẵn sàng. Bạn có muốn tải lại trang để trải nghiệm những cập nhật mới nhất không?'
  },
  exclusive: {
    id: `${scope}.exclusive`,
    defaultMessage: 'Độc quyền'
  },
  onlyForSpecialBusiness: {
    id: `${scope}.onlyForSpecialBusiness`,
    defaultMessage: 'Bán cho Doanh nghiệp đặc biệt'
  },
  onlyForSpecialBusinessDetail: {
    id: `${scope}.onlyForSpecialBusinessDetail`,
    defaultMessage: 'Sản phẩm này được bán độc quyền cho Doanh nghiệp đặc biệt.'
  },
  talkToSale: {
    id: `${scope}.talkToSale`,
    defaultMessage: 'Liên hệ mua hàng'
  },
  kamereoBrand: {
    id: `${scope}.kamereoBrand`,
    defaultMessage: 'Thương hiệu Kamereo',
  },
  kamereoBrandSubText: {
    id: `${scope}.kamereoBrandSubText`,
    defaultMessage: 'Tiện lợi, tiết kiệm',
  },
  gyomuSuper: {
    id: `${scope}.gyomuSuper`,
    defaultMessage: 'Gyomu Super',
  },
  gyomuSuperSubText: {
    id: `${scope}.gyomuSuperSubText`,
    defaultMessage: 'Thế giới hàng Nhật nội địa',
  },
  combo: {
    id: `${scope}.combo`,
    defaultMessage: 'Combo',
  },
  comboSubText: {
    id: `${scope}.comboSubText`,
    defaultMessage: 'Mua nhiều giá tốt',
  },
  dalatVeggies: {
    id: `${scope}.dalatVeggies`,
    defaultMessage: 'Rau củ Đà Lạt',
  },
  dalatVeggiesSubText: {
    id: `${scope}.dalatVeggiesSubText`,
    defaultMessage: 'Tươi ngon mỗi ngày',
  },
  trustedBrands: {
    id: `${scope}.trustedBrands`,
    defaultMessage: 'Thương hiệu đáng tin cậy',
  },
  commingSoon: {
    id: `${scope}.commingSoon`,
    defaultMessage: 'Sắp ra mắt',
  },
  commingSoonMessage: {
    id: `${scope}.commingSoonMessage`,
    defaultMessage: 'Kamereo sẽ mang đến hàng trăm sản phẩm nội địa Nhật chất lượng cao',
  },
  KAMEREO_VEGGIES: {
    id: `${scope}.KAMEREO_VEGGIES`,
    defaultMessage: 'Rau, Củ, Quả Thương Hiệu Kamereo',
  },
  KAMEREO_ECO: {
    id: `${scope}.KAMEREO_ECO`,
    defaultMessage: 'Sản Phẩm Thân Thiện Môi Trường Thương Hiệu Kamereo',
  },
  KAMEREO_DAILY: {
    id: `${scope}.KAMEREO_DAILY`,
    defaultMessage: 'Sản Phẩm Tiện Lợi, Tiết Kiệm Thương Hiệu Kamereo',
  },
});
