const tagsSlug = require('../../categoriesSlug/tagsSlug.json');

const suffixCategoriesRegexp = {
  categories: {
    codename: /(-?c)[\d]{1,5}/gi,
  },
  tags: {
    codename: /[A-Z_]+/,
  },
  page: {
    codename: 'page=',
  },
};

const getCategoryIDCodename = (slug) => {
  const codenameRegexp = new RegExp(suffixCategoriesRegexp.categories.codename);
  const codename = codenameRegexp.exec(slug);
  if (codename) {
    return codename[0].replace(codename[1], '');
  }
  return '';
}

const handleTagFromSlug = (slug, lang, pageQuery) => {
  const slugArray = slug.split('/');
  if (slugArray.length === 1) {
    const targetSlug = tagsSlug.data.find(tag => tag.path === `/${slug}`);
    return {
      ...targetSlug,
      path: `/${slug}${lang}${pageQuery}`,
    }
  }
  return '';
}

module.exports = { suffixCategoriesRegexp, getCategoryIDCodename, handleTagFromSlug }