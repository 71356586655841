import * as Sentry from '@sentry/browser';

export const crashReporter = (store) => (next) => (action) => {
  try {
    if (process.env.APP_ENV !== 'development') {
      if (action.type) {
        Sentry.addBreadcrumb({
          category: 'redux action',
          message: action.type,
          level: Sentry.Severity.Info,
          type: 'default',
        });
      }

      if (action.type && !/_SUCCESS$/.test(action.type) && action.payload) {
        Sentry.addBreadcrumb({
          category: 'redux payload',
          message: JSON.stringify(action.payload),
          level: Sentry.Severity.Info,
          type: 'default',
        });
      }
    }

    return next(action); // dispatch
  } catch (error) {
    if (process.env.APP_ENV !== 'development') {
      Sentry.withScope((scope) => {
        scope.setExtra('action', action);
        scope.setExtra('state', store.getState());
        Sentry.captureException(error);
      });
    }
    throw error; // re-throw error
  }
};
