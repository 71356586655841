import { Spin } from 'antd';
import * as React from 'react';

import Wrapper from './Wrapper';
interface Props {
  isHomePage?: boolean;
}
const LoadingIndicator: React.FC<Props> = ({ isHomePage }) => (
  <Wrapper isHomePage={isHomePage}>{!isHomePage && <Spin spinning={true} />}</Wrapper>
);

export default LoadingIndicator;
