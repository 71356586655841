import { Viewport } from 'components/ViewportProvider';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import messages from 'utils/messages';
import { viewPermissions } from 'utils/permissionMap';
import Favorite from './Favorite.svg';
import { CartIcon, FavoriteIcon, OrderIcon, ProfileIcon, ShopIcon } from './NavMobileIcon';
import Order from './Order.svg';
import { StyledLink } from './TopNavComponents';
import './topNav.css';
import SVGIcon from 'components/SVGIcon/SVGIcon';

interface ITopNavProps extends RouteComponentProps {
  cart: { cartSet: any[] };
  permissions: string[];
}

const TopNavCategoryContainer = styled.div`
  position: relative;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0;
  }
  padding: 0 10pt;
`;

const TopNavCategoryItemIcon = styled(SVGIcon)`
  display: flex;
  align-items: center;
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

const TopNavBadgeMobile = styled.div`
  position: absolute;
  top: -6px;
  right: -12px;
  width: 20px;
  height: 20px;
  background: #ff4852;
  text-align: center;
  color: #fff;
  border-radius: 50%;
`;

const TopNavCategoryWrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  background: #fcfffc;
  width: 100%;
  height: 70px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 992px) {
    display: block;
  }
`;

const CartWrapper = styled.div`
  position: relative;
`;

const TopNavCategoryItem = (props: any) => {
  const { children, onNavigate, opened, favorite, ...rest } = props;
  return (
    <StyledLink
      activeClassName="active"
      onClick={onNavigate}
      favorite={favorite ? 'true' : 'false'}
      {...rest}
      exact={true}
    >
      {props.children}
    </StyledLink>
  );
};

const TopNavCategory: FC<ITopNavProps> = (props) => {
  const { cart, permissions: userPermissions, location } = props;
  const loggedIn = !!localStorage.getItem('token');
  const path = location.pathname;

  const canAccessView = (path: string): boolean => {
    const permissions: string[] = viewPermissions[path];
    if (permissions) {
      let permissionCounter = 0;
      for (const permission of permissions) {
        if (userPermissions.indexOf(permission) > -1) {
          permissionCounter += 1;
        }
      }

      if (permissionCounter !== permissions.length) {
        return false;
      }
    }
    return true;
  };

  return (
    <TopNavCategoryWrapper>
      <Viewport.Consumer>
        {({ width }) => (
          <TopNavCategoryContainer>
            <TopNavCategoryItem to="/" className={path === '/' && 'active'}>
              <ShopIcon isActive={path === '/'} />
              <FormattedMessage {...messages.shop} />
            </TopNavCategoryItem>
            {loggedIn ? (
              <>
                {canAccessView('/favorite') && (
                  <>
                    <TopNavCategoryItem to="/favorite" favorite>
                      {width > 1024 ? (
                        <TopNavCategoryItemIcon src={Favorite} />
                      ) : (
                        <FavoriteIcon isActive={path === '/favorite'} />
                      )}
                      <FormattedMessage {...messages.favorite} />
                    </TopNavCategoryItem>
                  </>
                )}
                {canAccessView('/orders') && (
                  <>
                    <TopNavCategoryItem
                      to="/orders"
                      className={
                        (path === '/orders' ||
                          path === '/orders-pending-approval' ||
                          path === '/pending-orders-history') &&
                        'active'
                      }
                    >
                      {width > 1024 ? (
                        <>
                          <TopNavCategoryItemIcon src={Order} />
                          <FormattedMessage {...messages.orders} />
                        </>
                      ) : (
                        <>
                          <OrderIcon
                            isActive={
                              path === '/orders' ||
                              path === '/orders-pending-approval' ||
                              path === '/pending-orders-history'
                            }
                          />
                          <FormattedMessage {...messages.ordersMobileDisplay} />
                        </>
                      )}
                    </TopNavCategoryItem>
                  </>
                )}
                {canAccessView('/checkout/step/1') && (
                  <>
                    <TopNavCategoryItem
                      to="/checkout/step/1"
                      className={
                        (path === '/checkout/step/1' ||
                          path === '/checkout/step/2' ||
                          path.substring(0, path.lastIndexOf('/')) === '/checkout/step/confirm') &&
                        'active'
                      }
                      cart="true"
                    >
                      <CartWrapper>
                        <TopNavBadgeMobile>
                          {cart.cartSet.map((cartSet) => cartSet.items.length).reduce((a, b) => a + b, 0)}
                        </TopNavBadgeMobile>
                        <CartIcon
                          isActive={
                            path === '/checkout/step/1' ||
                            path === '/checkout/step/2' ||
                            path.substring(0, path.lastIndexOf('/')) === '/checkout/step/confirm'
                          }
                        />
                      </CartWrapper>
                      <FormattedMessage {...messages.cartDetails} />
                    </TopNavCategoryItem>
                  </>
                )}
                {canAccessView('/account-mobile') && (
                  <>
                    <TopNavCategoryItem to="/account-mobile">
                      <ProfileIcon isActive={path === '/account-mobile'} />
                      <FormattedMessage {...messages.accountMobileDisplay} />
                    </TopNavCategoryItem>
                  </>
                )}
              </>
            ) : width < 1025 ? (
              <>
                <TopNavCategoryItem to="/signin" favorite rel="nofollow">
                  <TopNavCategoryItemIcon src={Favorite} />
                  <FormattedMessage {...messages.favorite} />
                </TopNavCategoryItem>
                <TopNavCategoryItem to="/signin" rel="nofollow">
                  <TopNavCategoryItemIcon src={Order} />
                  <FormattedMessage {...messages.ordersMobileDisplay} />
                </TopNavCategoryItem>
                <TopNavCategoryItem to="/signin" cart="true" rel="nofollow">
                  <CartWrapper>
                    <CartIcon isActive={false} />
                    <TopNavBadgeMobile>0</TopNavBadgeMobile>
                  </CartWrapper>
                  <FormattedMessage {...messages.cartDetails} />
                </TopNavCategoryItem>
                <TopNavCategoryItem to="/signin" rel="nofollow">
                  <ProfileIcon isActive={path === '/account-mobile'} />
                  <FormattedMessage {...messages.accountMobileDisplay} />
                </TopNavCategoryItem>
              </>
            ) : (
              <>
                <TopNavCategoryItem to="/signin" favorite rel="nofollow">
                  {width > 1024 ? (
                    <TopNavCategoryItemIcon src={Favorite} />
                  ) : (
                    <FavoriteIcon isActive={path === '/favorite'} />
                  )}
                  <FormattedMessage {...messages.favorite} />
                </TopNavCategoryItem>
                <TopNavCategoryItem to="/signin" rel="nofollow">
                  <TopNavCategoryItemIcon src={Order} />
                  <FormattedMessage {...messages.orders} />
                </TopNavCategoryItem>
              </>
            )}
          </TopNavCategoryContainer>
        )}
      </Viewport.Consumer>
    </TopNavCategoryWrapper>
  );
};

export default withRouter(TopNavCategory);
